<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('code')">
                            <b-form-input type="text" v-model="formData.code"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="9">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.name"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('short_name')">
                        <ValidationProvider name="short_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.short_name"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('diploma_name')">
                        <ValidationProvider name="diploma_name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.diploma_name"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('card_name')">
                        <ValidationProvider name="card_name" rules="" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.card_name"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <ValidationProvider name="dean_title" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('dean_title')">
                            <parameter-selectbox v-model="formData.dean_title"
                                                 :validate-error="errors[0]"
                                                 code="dean_titles"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <div class="col-12 col-sm-6 col-md">
                    <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox v-model="formData.level"
                                                 :validate-error="errors[0]"
                                                 code="faculty_levels"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-sm-6 col-md">
                    <ValidationProvider name="credit_limit" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('credit_limit')">
                            <b-form-input type="number"
                                          v-model="formData.credit_limit"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-sm-6 col-md">
                    <ValidationProvider name="dean_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('dean')">
                            <staff-auto-complete v-model="formData.dean_id"
                                                 :validate-error="errors[0]"
                                                 :valueObject="deanObject"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </div>

<!--                <div class="col-12 col-sm-6 col-md">-->
<!--                    <ValidationProvider name="campus_id" rules="required" v-slot="{valid, errors}">-->
<!--                        <b-form-group :label="$t('campus')">-->
<!--                            <campus-selectbox v-model="formData.campus_id"-->
<!--                                              :state="errors[0] ? false : null"-->
<!--                                              :validate-error="errors[0]"/>-->
<!--                        </b-form-group>-->
<!--                    </ValidationProvider>-->
<!--                </div>-->
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';

    // Services
    import FacultyService from '@/services/FacultyService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            CampusSelectbox,
            ParameterSelectbox,
            StaffAutoComplete,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: String
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    code: null,
                    name: null,
                    short_name: null,
                    diploma_name: null,
                    card_name: null,
                    dean_title: null,
                    level: null,
                    credit_limit: null,
                    dean_id: null,
                    campus_id: null
                },
                deanObject: {}
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                FacultyService.get(id)
                              .then(response => {
                                  let data = response.data.data;
                                  this.formData = {
                                      code: data.code,
                                      name: data.name,
                                      short_name: data.short_name,
                                      diploma_name: data.diploma_name,
                                      card_name: data.card_name,
                                      dean_title: data.dean_title,
                                      level: data.level,
                                      credit_limit: data.credit_limit,
                                      dean_id: data.dean_id,
                                      campus_id: data.campus_id
                                  }
                                  if (data.dean && data.dean.person) {
                                      this.deanObject = {
                                          value: data.dean_id,
                                          text: data.dean.person.name + ' ' + data.dean.person.surname
                                      }
                                  }
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            },

            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    FacultyService.update(this.formId, this.formData)
                                  .then(response => {
                                      this.$emit('updateFormSuccess', true);
                                      this.$toast.success(this.$t('api.' + response.data.message));
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate);
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })
                }
            }
        }
    }
</script>
